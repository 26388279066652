import { useRef, useState, useEffect, useCallback } from 'react'
import { useMutation } from '@apollo/client'
import styled, { css } from 'styled-components'

import { Analytics } from 'analytics/Analytics'
import { Maybe, ProfileType } from '@graph/types/global.generated'
import { ErrorMessage } from '@ui/form/ErrorMessage'
import { extractAllFieldsErrorFromMutation, updateViewerCache } from '@graph/utils'
import { ViewerGlobalFragmentDoc } from '@graph/fragments/ViewerGlobal.generated'
import Stack from '@ui/layout/stack'
import { clearOnboardingLocalStorage } from 'onboarding/utils'
import { captureSentryException } from 'app/utils'

import { GoogleLoginMutationDocument, GoogleSignupMutationDocument } from './mutations.generated'
import useGoogleSignInSdk from './useGoogleSignInSdk'
import { clientOnlyJwtParser } from './utils'

export enum GoogleAuthActionTypes {
  SIGNUP = 'singup',
  LOGIN = 'login',
  NONE = 'none',
}

const BUTTON_CLICK_TARGET = 'google-login'

type GoogleAuthButtonRequiredProps = {
  disabled: boolean
}

type GoogleAuthButtonSignupProps = GoogleAuthButtonRequiredProps & {
  action: GoogleAuthActionTypes.SIGNUP
  profileType: ProfileType
  onAuthenticated: () => void
}

type GoogleAuthButtonLoginProps = GoogleAuthButtonRequiredProps & {
  action: GoogleAuthActionTypes.LOGIN
  viewerEmail?: Maybe<string>
  onAuthenticated: () => void
}

type GoogleAuthButtonNoActionProps = GoogleAuthButtonRequiredProps & {
  action: GoogleAuthActionTypes.NONE
  onGoogleAuthenticated: (accessToken: string) => void
}

type GoogleAuthButtonProps =
  | GoogleAuthButtonSignupProps
  | GoogleAuthButtonLoginProps
  | GoogleAuthButtonNoActionProps

const GoogleAuthButton = (props: GoogleAuthButtonProps) => {
  const { sdk } = useGoogleSignInSdk()
  const [error, setError] = useState<string>()
  const buttonRef = useRef(null)
  const { action, disabled } = props
  const viewerEmail = (props as Pick<GoogleAuthButtonLoginProps, 'viewerEmail'>).viewerEmail
  const profileType = (props as Pick<GoogleAuthButtonSignupProps, 'profileType'>).profileType
  const onAuthenticated = (props as GoogleAuthButtonSignupProps | GoogleAuthButtonLoginProps)
    .onAuthenticated
  const onGoogleAuthenticated = (
    props as Pick<GoogleAuthButtonNoActionProps, 'onGoogleAuthenticated'>
  ).onGoogleAuthenticated

  const [googleSignupUser, { loading: isSubmittingSignup }] = useMutation(
    GoogleSignupMutationDocument,
    {
      update(cache, { data }) {
        updateViewerCache(
          cache,
          data?.googleSignupUser?.viewer?.user,
          ViewerGlobalFragmentDoc,
          'ViewerGlobal'
        )
      },
    }
  )

  const [googleLoginUser, { loading: isSubmittingLogin }] = useMutation(
    GoogleLoginMutationDocument,
    {
      update(cache, { data }) {
        updateViewerCache(
          cache,
          data?.googleLoginUser?.viewer?.user,
          ViewerGlobalFragmentDoc,
          'ViewerGlobal'
        )
      },
    }
  )

  const handleSignup = useCallback(
    async (accessToken: string, profile: ProfileType) => {
      if (action === GoogleAuthActionTypes.NONE || disabled) {
        return
      }

      const response = await googleSignupUser({
        variables: { input: { accessToken, profileType: profile } },
      })

      const error = extractAllFieldsErrorFromMutation(response)

      if (error) {
        Analytics.trackErrorView('authentication', BUTTON_CLICK_TARGET, error)
        setError(error)
      } else {
        Analytics.trackObjectCreate(profile)
        clearOnboardingLocalStorage(true)
        onAuthenticated()
      }
    },
    [googleSignupUser, action, disabled, onAuthenticated]
  )

  const handleLogin = useCallback(
    async (accessToken: string) => {
      const response = await googleLoginUser({
        variables: { input: { accessToken } },
      })

      const error = extractAllFieldsErrorFromMutation(response)

      if (error) {
        Analytics.trackErrorView('authentication', BUTTON_CLICK_TARGET, error)
        setError(error)
      } else {
        clearOnboardingLocalStorage(true)
        onAuthenticated()
      }
    },
    [googleLoginUser, onAuthenticated]
  )

  const handleCredentialResponse = useCallback(
    async (response: google.accounts.id.CredentialResponse) => {
      Analytics.trackButtonClick(BUTTON_CLICK_TARGET)

      const { credential: accessToken } = response

      if (action === GoogleAuthActionTypes.NONE) {
        return onGoogleAuthenticated(accessToken)
      }

      const authenticate =
        action === GoogleAuthActionTypes.SIGNUP
          ? () => handleSignup(accessToken, profileType)
          : () => handleLogin(accessToken)

      /**
       * If viewerEmail is provided, we need to check if the email from the accessToken
       * matches the viewer's email we're trying to sign in.
       */
      if (action === GoogleAuthActionTypes.LOGIN && viewerEmail) {
        try {
          const { email } = clientOnlyJwtParser(accessToken)

          email === viewerEmail
            ? authenticate()
            : setError("This Google account's email doesn't match your account's email.")
        } catch (error) {
          captureSentryException(error)
          // Since this is not critical functionality I don't want any potential signins to fail
          // due to an unexpected parsing error.
          authenticate()
        }
      } else {
        authenticate()
      }
    },
    [action, handleSignup, handleLogin, profileType, viewerEmail, onGoogleAuthenticated]
  )

  useEffect(() => {
    const clientId = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID
    if (!sdk || !sdk.accounts || !buttonRef.current || !clientId) return

    sdk.accounts.id.initialize({
      client_id: clientId,
      callback: handleCredentialResponse,
    })
    sdk.accounts.id.renderButton(buttonRef.current, {
      theme: disabled ? 'outline' : 'filled_blue',
      size: 'large',
      text: action === GoogleAuthActionTypes.SIGNUP ? 'signup_with' : 'signin_with',
      width: 300,
      type: 'standard',
    })
  }, [sdk, handleCredentialResponse, profileType, disabled, action])

  return (
    <Stack gap='2' align='center'>
      <GoogleButton
        ref={buttonRef}
        disabled={disabled || isSubmittingSignup || isSubmittingLogin}
      />

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Stack>
  )
}

const GoogleButton = styled.div<{ disabled: boolean }>`
  // used for fixing flickering issue when loading
  height: 44px;
  div > div:first-child {
    display: none;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

export default GoogleAuthButton
